.add-faq-wrapper {
  .form-group {
    margin-bottom: 0;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 350px;
  }

  .react-tagsinput {
    padding-left: 30px;
  }

  .react-tagsinput--focused {
    border-color: #e73d8e;
  }

  .react-tagsinput-input {
    font-size: 14px;
  }

  .react-tagsinput-tag {
    background-color: #e73d8e;
    border-color: #e73d8e;
    color: #fff;
  }

  .react-tagsinput-remove {
    color: #fff;
  }

  .submit-btn,
  .add-sub-question-btn {
    background-color: #e73d8e;
    color: #fff;
    font-size: 15px;
    padding: 6px 20px;
    margin-top: 20px;
  }

  .add-sub-question-btn {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .remove-btn {
    background: transparent;
    border-color: #474747;
    color: #474747;
    font-size: 13px;
    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.manage-faq-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  .manage-faq {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    .add_btn {
      background-color: #4fa0ca;
      border: 1px solid #4fa0ca;
      color: #fff;
      padding: 7px 30px;
      font-size: 14px;
      transition: 0.3s all;
      line-height: 21px;
      text-decoration: none;
      transition: 0.3s all;

      &:hover {
        background-color: #2e58a6;
      }
    }

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    .icon {
      color: #4fa0ca;
    }

    img {
      width: 40px;
      border-radius: 50%;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;
    }

    .Active,
    .Inactive {
      background-color: green;
      color: #fff;
      padding: 5px 15px;
      font-size: 12px;
    }

    .Inactive {
      background-color: tomato;
    }

    h6 {
      font-weight: 400 !important;
    }
  }
}

@media (max-width: 767px) {
  .manage_category_page {
    .col-md-5.pr-0 {
      width: auto;
      margin-left: auto;

      input {
        width: 220px;
      }
    }
  }
}

@media (max-width: 575px) {
  .manage_category_page {
    .col-md-5.pr-0 {
      margin-top: 20px;
      width: 100%;
      padding: 0;

      input {
        width: 100%;
      }
    }
  }
}
