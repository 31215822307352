
.appointments-wrapper {

  .select-status {
    background-color: #edf9ff;
    border-radius: 50px;
    border: 1px solid #e5f6ff;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
    color: #717171;
    height: 25px;
  }
  
  .right_col button {
    float: right;
    background-color: #edf9ff;
    border-radius: 50px;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%) !important;
    font-size: 13px;
    font-weight: 600;
    color: #717171;
  }
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }
  .appointments {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    text-align: Center;
    margin: 25px 0px 0px 0;

    i {
      font-size: 16px;
      color: #474747;
    }

    .icon {
      color: #4fa0ca;
    }

    .date-picker-label {
      font-size: 12px;
      font-weight: 600;
      color: #474747;
      margin-bottom: 2px;
      display: flex;
      align-items: start;
    }

    .form-control {
      padding-left: 10px;
      font-size: 14px;
      background-color: #e5f6ff;
      border-radius: 50px;
      border: 1px solid #dedede;
    }

    .appointments-filter-submit-btn {
      background-color: #4fa0ca;
      color: #fff;
      padding: 5px 20px 6px 20px;
      font-size: 14px;

      &:hover {
        background-color: #2e58a6;
      }
    }

    .react-datepicker {
      button {
        outline: none !important;
      }
    }
  }
}

.datepicker-wrapper {
  position: relative;
  display: inline-block;
}

.datepicker-input {
  padding-left: 30px !important;
  cursor: pointer;
}

.calendar-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}