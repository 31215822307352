
.export-btn {
  background-color: #4fa0ca;
  color: #fff;
  padding: 5px 20px 6px 20px;
  font-size: 14px;

  &:hover {
    background-color: #2e58a6;
  }
}


.ongoings
.pendings {
  color: #fff;
  background-color: #FFA500;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}
.cancelleds
.rejecteds {
  color: #fff;
  background-color: red;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.approveds
.completeds
.confirmeds {
  color: #fff !important;
  background-color: green !important;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.plus-text {
  color: #404f5e;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0;
  background-color: white;
  border-radius: 50%;
  width: 24px;
  text-align: center;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarName {
  color: white !important;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0;
  background-color: grey;
  border-radius: 50%;
  width: 40px;
  text-align: center;
  height: 40px;
  margin-left: 150px;
  font-size: 20px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mw-170 {
  min-width: 170px;
}

.max-w {
  max-width: 180px;
}

.mw_120 {
  min-width: 120px;
}

.center-text {
  text-align: center;
}

.select-status {
  background-color: #edf9ff;
  border-radius: 50px;
  border: 1px solid #e5f6ff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #717171;
  height: 25px;
}

.manage-doctor-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  .search_col {
    .form-group {
      margin-bottom: 0;
      input {
        border-radius: 50px;
        background-color: #edf9ff;
        border: 1px solid #e5f6ff;
      }
    }
  }

  .right_col {
    button {
      float: right;
      background-color: #edf9ff;
      border-radius: 50px;
      box-shadow: 0 0 30px rgb(0 0 0 / 3%) !important;
      font-size: 13px;
      font-weight: 600;
      color: #717171;
      border: none;
    }
  }

  .manage-doctor {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    button {
      background-color: #4fa0ca;
      border-color: #4fa0ca;

      &:hover {
        background-color: #2e58a6;
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 20px;
    }

    td.approve {
      span {
        cursor: pointer;
      }
    }

    th,
    td {
      font-size: 14px;
      color: #474747;
      vertical-align: middle;

      span.complete{
        color:green;
      }

      span.incomplete{
        color:red;
      }

      span.pendings{
        color:#FFA500;
      }
    }

    h6 {
      font-weight: 400 !important;
    }

    .icon {
      color: #4fa0ca;
    }

    img {
      width: 40px;
      height: 40px;
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
    }

    .active,
    .suspended {
      background-color: rgb(93, 155, 0);
      color: #fff;
      padding: 4px 15px;
      font-size: 13px;
      border-radius: 50px;
    }

    .suspended {
      background-color: tomato;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;

      &:hover {
        color: #4fa0ca;
      }
    }

    .add-doctor-btn {
      font-size: 14px;
      background-color: #e73d8e;
      color: #fff;
      padding: 6px 25px;
    }

    select {
      height: 35px;
      font-size: 13px;
      padding: 5px 20px;
    }
  }
}

.doctor-details {
  h3 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  hr {
    margin-bottom: 30px;
  }

  .basic-details {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    text-align: Center;

    h6 {
      padding: 0;
    }

    sup {
      top: 0;
      font-size: 12px;
    }

    .details-image {
      width: 170px;
      height: 170px;
      max-width: 100%;
      max-height: 300px;
      margin-bottom: 20px;
    }

    h1 {
      font-size: 20px;
      font-weight: 400;
      color: #474747;
    }

    h6 {
      font-size: 14px;
      font-weight: 400;
      color: #474747;
      padding-top: 5px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      color: #474747;
      margin-bottom: 0;
    }

    .verified,
    .unverified {
      color: #fff;
      background-color: green;
      padding: 4px 15px;
      font-size: 10px;
      font-weight: 200;
      border-radius: 2px;
    }

    .unverified {
      background-color: #4fa0ca;
    }
  }

  .proof {
    padding: 15px;
  }

  .personal-details {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    .input-icons {
      position: relative;
    }

    .form-control {
      padding-left: 40px;
      font-size: 15px;
      border-radius: 50px;
      border: 1px solid #f1f1f1;
      height: 45px;
    }

    .icon {
      color: #4fa0ca;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
    }

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: #474747;
      padding-bottom: 0;
      margin-bottom: 25px;
      text-transform: uppercase;
    }

    .update-details-btn {
      font-size: 13px;
      border-radius: 50px;
      padding: 8px 0;
      background-color: #474747;
      border-color: #474747;
      transition: 0.3s all;
      height: 45px;
      text-transform: uppercase;

      &:hover {
        background-color: #474747;
      }
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .appointment-history {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    margin-top: 30px;

    th,
    td {
      font-size: 14px;
    }

    h5 {
      font-size: 15px;
      font-weight: 600;
      color: #474747;
      padding-bottom: 0;
      margin-bottom: 25px;
      text-transform: uppercase;
    }

    a {
      color: #474747;
      text-decoration: none;
    }
  }

  .ratings {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 30px;

    th,
    td {
      font-size: 14px;
    }

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }

    a {
      color: #474747;
      text-decoration: none;
    }
  }

  .earnings {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 30px;
    text-align: center;
    height: auto;
    max-width: 100%;
    overflow-x: auto;

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
      text-align: left;
    }
  }

  .health-articles {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    margin-top: 30px;

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }

    th,
    td {
      font-size: 14px;
    }

    a {
      color: #474747;
      text-decoration: none;
    }
  }

  .other-details {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 25px;
    margin-top: 30px;

    h5 {
      font-size: 15px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 15px;
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: #e73d83;
      background-color: #fff;
      border-color: #e73d8e #e73d8e #fff;
      font-size: 15px;
      padding-top: 8px;
    }

    a {
      color: #474747;
      text-decoration: none;
      background-color: transparent;
    }

    .nav-item {
      font-size: 15px;
    }

    .tab-content-item {
      background-color: #fff;
      color: #474747;
      font-size: 15px;
      padding: 30px 0;

      li {
        font-size: 12px;
        list-style: none;
        display: inline;
        background-color: #e73d83;
        color: #fff;
        padding: 7px 20px 8px 20px;
        border-radius: 3px;
        margin-right: 20px;
        margin-bottom: 20px;
      }

      .profile-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }

  .proof_row {
    margin-top: 30px;

    .proof_heading {
      font-size: 19px;
      margin-bottom: 20px;
    }
    .proof_img {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }

    .basic-details{
      position:relative;

      .fa{
        position:absolute;
        right: 30px;
        background-color: rgba(0 0 0 / 20%);
        padding: 6px;
        color: #fff;
        font-size: 12px;
        border-radius: 50%;
      }
    }
  }
}

.active,
.Active,
.Inactive,
.suspended,
.verified,
.unverified,
.pending,
.confirmed,
.cancelled,
.no-show,
.completed {
  color: #fff;
  background-color: green;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.pending,
.no-show {
  background-color: rgb(204, 168, 6);
}

.suspended,
.Inactive,
.unverified,
.cancelled {
  background-color: tomato;
}

@media (max-width: 991px) {
  .doctor-details {
    .basic-details img.details-image {
      width: 200px !important;
      height: 200px;
    }
  }
}

@media (max-width: 767px) {
  .manage_walkers_page {
    h5 {
      font-size: 20px;
    }

    .col-md-5.pr-0 {
      margin-left: auto;
      width: auto;

      input {
        width: 220px;
      }
    }
  }

  .doctor-details {
    h3 {
      font-size: 20px;
    }
    .col-md-8.pr-0 {
      padding: 0;
    }
  }
}

@media (max-width: 575px) {
  .manage_walkers_page {
    .col-md-5.pr-0 {
      padding: 0;
      width: 100%;
      margin-top: 15px;

      input {
        width: 100%;
      }
    }
  }
}
