.right_content {
  width: calc(100% - 220px);
  float: right;
  padding: 0 25px;

  .main {
    margin-bottom: 30px;
  }
}

.right_content.collapsed {
  width: calc(100% - 80px);
}
