.profile-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 0px;
  }

  .profile-img {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    img {
      max-width: 100%;
      width: 180px;
      height: 180px;
      max-height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
      padding-top: 20px;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      padding-top: 10px;
      margin-bottom: 0;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }

    label {
      border: 1px solid #4fa0ca;
      color: #4fa0ca;
      background-color: transparent;
      padding: 5px 12px;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      margin-top: 25px;
      transition: 0.4s;
    }

    label:hover {
      cursor: pointer;
      background-color: #4fa0ca;
      color: #fff;
    }
  }

  .profile-details {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    .input-icons {
      position: relative;
    }

    .form-control {
      padding-left: 40px;
      font-size: 15px;
      border-radius: 50px;
      border: 1px solid #f1f1f1;
      height: 45px;
    }

    h4 {
      font-size: 17px;
      font-weight: 400;
      padding-bottom: 10px;
    }

    i {
      color: #4fa0ca;
      top: 50%;
      transform: translateY(-50%);
      left: 6px;
    }

    .update-profile-btn {
      font-size: 13px;
      border-radius: 50px;
      padding: 8px 0;
      background-color: #474747;
      border-color: #474747;
      transition: 0.3s all;
      height: 45px;
      text-transform: uppercase;

      &:hover {
        background-color: #474747;
      }
    }
  }
}
