.manage-coupon-wrapper {
  margin: 40px 30px 40px 0;

  h5 {
    font-size: 22px !important;
    font-weight: 400 !important;
    color: #474747;
  }

  .manage-coupon {
    margin: 25px 0px 0px 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 45px;
    border-radius: 3px;

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    .results {
      margin-top: 48px;
    }

    i {
      font-size: 16px;
      color: #474747;
    }

    .fa-edit,
    .fa-trash {
      padding-left: 12px;
    }

    .icon {
      color: #e73d8e;
    }

    .form-control {
      padding-left: 20px;
      font-size: 14px;
    }

    .Active,
    .Inactive {
      background-color: green;
      padding: 5px 15px;
      border-radius: 1px;
      font-size: 12px;
      color: #fff;
    }

    .Inactive {
      background-color: tomato;
    }

    .add-coupon-btn {
      background-color: #e73d8e;
      color: #fff;
      padding: 5px 20px 6px 20px;
      font-size: 14px;
    }
  }
}
