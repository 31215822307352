.admin-forgot-password {
  padding: 40px 0;

  h5 {
    font-size: 15px;
    font-weight: 600;
    color: #474747;
    padding-bottom: 0;
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  .forgot-password-btn {
    font-size: 13px;
    border-radius: 50px;
    padding: 8px 0;
    background-color: #474747;
    border-color: #474747;
    transition: all 0.3s;
    height: 45px;
    text-transform: uppercase;
  }

  .fa {
    color: #4fa0ca;
    top: 50%;
    transform: translateY(-50%);
  }

  a {
    color: #474747;
    text-decoration: none;
    font-size: 14px;
  }

  .card {
    width: 100%;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    border-radius: 20px;
    border-color: rgba(0, 0, 0, 0.05);

    .card-body {
      padding: 0;

      .form-group {
        position: relative;
      }

      .form-control {
        padding-left: 40px;
        font-size: 15px;
        border-radius: 50px;
        border: 1px solid #f1f1f1;
        height: 45px;
      }
    }
  }
}
