.datePickwerWidth {
  width: 190px;
}

.custom-button-color-black {
  background-color: #474747 !important;
  border-color: #474747 !important;
}

.page-link {
  color: #474747;
  transition:0.3s all;
  padding: 4px 15px;
  font-size: 13px;
  border-radius: 20px !important;

  &:hover{
    background-color:#4fa0ca;
    color:#fff;
  }
}

h3 {
  font-size: 22px !important;
  font-weight: 400;
  padding: 15px 0px;
}

.h4 {
  font-size: 18px !important;
  font-weight: 400 !important;
  padding: 0 15px;
}

h6 {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 15px 0 0 15px;
}

.bundle-header {
  font-size: 15px !important;
  font-weight: 600 !important;
  padding: 15px 0 0 0;
}
.subscription-h6 {
  font-weight: 400 !important;
  padding: 0;
}

.modal-title {
  padding: 0;
}

.modal-body {
  font-size: 15px;
}

.yes-btn,
.no-btn {
  font-size: 14px;
  font-weight: 400;
  padding: 3px 20px;
}

.form-control {
  padding-left: 35px;
  font-size: 15px;
}

.date-picker-ui {
  padding-left: 10px;
  font-size: 14px;
  background-color: #e5f6ff;
  border-radius: 50px;
  border: 1px solid #dedede;
}

.add-specialty-btn,
.add-symptom-btn,
.add-doctor-btn,
.add-patient-btn {
  background-color: #4fa0ca;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
  border-color: #4fa0ca;

  &:hover {
    background-color: #2e58a6;
  }
}

label {
  display: block;
}

form .fa-user-md,
form .fa-user,
form .fa-envelope,
form .fa-phone {
  color: #e73d8e;
}

.suspended,
.active {
  background-color: green;
  color: #fff;
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 2px;
}

.suspended {
  background-color: tomato;
}

form .fa {
  color: #4fa0ca;
}

.update-details-btn {
  background-color: #e73d8e;
  color: #fff;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 2px;
  border-color: #e73d8e;
}

.assign-modules-heading {
  padding-top: 15px;
}

.assign-modules-subheading {
  padding-top: 15px;
  padding-left: 15px;
}

.assign-modules-btn {
  background-color: #e73d8e;
  color: #fff;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 2px;
  border-color: #e73d8e;
  margin-top: 30px;
}

.doc-file-btn {
  background-color: #e73d8e;
  color: #fff;
  padding: 4px 15px;
  font-size: 13px;
  border-radius: 2px;
  border-color: #e73d8e;
}

.nutrition-update-btn {
  background-color: #e73d8e;
  color: #fff;
  padding: 4px 15px;
  font-size: 13px;
  border-radius: 2px;
  border-color: #e73d8e;
}

#edit-nutrition-img {
  width: 150px;
  height: 150px;
}

input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.upload-image {
  width: 150px;
  border: 1px solid #4fa0ca;
  color: #4fa0ca;
  background-color: transparent;
  padding: 5px 12px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  margin-top: 25px;
  transition: 0.4s;
  margin: 30px 0;
}

.upload-image:hover {
  cursor: pointer;
  background-color: #4fa0ca;
  color: #fff;
}

.update-plan-btn {
  background-color: #e73d8e;
  color: #fff;
  padding: 6px 20px;
  font-size: 13px;
  border-color: #e73d8e;
  margin-top: 20px;
}

.view-member-files {
  background-color: #e73d8e;
  color: #fff !important;
  padding: 4px 15px;
  font-size: 12px;
  border-color: #e73d8e;
  margin-left: 20px;
  text-decoration: none !important;
}

.close {
  outline: none !important;
}

.fa-check-circle {
  font-size: 25px !important;
  color: green;
  margin-bottom: 0 !important;
}

.fa-times-circle {
  font-size: 25px !important;
  color: tomato;
}

audio {
  outline: none !important;
}

._2iA8p44d0WZ-WqRBGcAuEV {
  border-radius: 0 !important;
  padding: 5px 15px !important;

  :focus {
    border-color: #e73d8e !important;
  }
}

.image_modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: none;

  .image_backdrop {
    background-color: rgba(0 0 0 / 35%);
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.image_modal.show {
  display: block;
}

.profile-details {
  background-color: #edf9ff;
  // box-shadow: 0 0 30px rgb(0 0 0 / 3%);
  padding: 30px;
  border-radius: 20px;

  .input-icons {
    position: relative;
  }

  .custom-select {
    outline: none;
}

.custom-select:focus {
    outline: none;
    box-shadow: none;
}

  .form-control-2 {
    padding: 8px;
    font-size: 15px;
    border-radius: 50px;
    border: 1px solid #f1f1f1;
    height: 45px;
    &.paddingLeft 
    {
      padding-left: 6px;
    }
    .form-control 
    {
      @extend .form-control-2;
    }
    &.form-group 
    {
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 17px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  i {
    color: #4fa0ca;
    top: 50%;
    // transform: translateY(-50%);
    left: 6px;
    &.fa-plus 
    {
      transform: unset;
      margin-left: 16px;
    }
  }

  .update-profile-btn {
    font-size: 13px;
    border-radius: 50px;
    padding: 8px 0;
    background-color: #474747;
    border-color: #474747;
    transition: 0.3s all;
    height: 45px;
    text-transform: uppercase;

    &:hover {
      background-color: #474747;
    }
  }
  .remove-profile-btn {
    font-size: 11px;
    border-radius: 50px;
    padding: 8px 0;
    background-color: #474747;
    border-color: #474747;
    transition: 0.3s all;
    height: 30px;
    text-transform: uppercase;

    &:hover {
      background-color: #474747;
    }
  }
}

.datepicker-wrapper {
  position: relative;
  display: inline-block;
}

.datepicker-input {
  padding-left: 30px !important;
  cursor: pointer;
  padding-left: 10px;
  font-size: 14px;
  background-color: white !important;
  border-radius: 50px;
  border: 1px solid #dedede;
}

.calendar-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.calendar-icon2 {
  position: absolute;
  left: 10px;
  top: 40%;
  transform: translateY(-50%);
  pointer-events: none;
}

// .modal-body-package {
  // background-color: #edf9ff;
  // box-shadow: 0 0 30px rgba(0, 0, 0, 0.03);
  // padding: 30px;
  // border-radius: 20px;
// }


.multiselect-wrapper {
  position: relative;
  .search-wrapper {
    border: none;
    align-items: center;
  }
  .searchBox {
    margin-top: 0px;
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  // margin-top: 2px;
  top: 6px;
  left: 6px;
  position: absolute;
}

.custom-chip {
  background-color: #007bff;
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 14px;
}

.extra-chip {
  background-color: #6c757d;
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 0px;
  font-size: 14px;
}
