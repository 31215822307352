.policy {
  padding: 40px;
  text-align: justify;

  h4 {
    font-size: 24px;
    font-weight: 400;
    color: #474747;
  }

  hr {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .policy {
    padding: 10px !important;

    h4 {
      font-size: 20px !important;
    }

    p {
      font-size: 16px !important;
      line-break: anywhere;
    }
  }
}
