.mw-120 {
  min-width: 120px;
}

.add-faq-wrapper {
  .faq_page {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    .form-control {
      font-size: 14px;
      background-color: #e5f6ff;
      border-radius: 50px;
      border: 1px solid #dedede;
    }

    .submit-btn {
      background-color: #474747 !important;
      border-radius: 50px;
    }
  }

  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    min-height: 350px;
  }

  .react-tagsinput {
    padding-left: 30px;
  }

  .react-tagsinput--focused {
    border-color: #e73d8e;
  }

  .react-tagsinput-input {
    font-size: 14px;
  }

  .react-tagsinput-tag {
    background-color: #e73d8e;
    border-color: #e73d8e;
    color: #fff;
  }

  .react-tagsinput-remove {
    color: #fff;
  }

  .submit-btn,
  .add-sub-question-btn {
    background-color: #4fa0ca !important;
    color: #fff;
    font-size: 15px;
    padding: 6px 20px;
    margin-top: 20px;
    transition: 0.3s all;

    &:hover {
      background-color: #2e58a6 !important;
    }
  }

  .add-sub-question-btn {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .remove-btn {
    background: transparent;
    border-color: #474747;
    color: #474747;
    font-size: 13px;
    padding: 4px 15px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.manage-faq-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 400;
    color: #474747;
  }

  .search_col {
    .form-group {
      margin-bottom: 0;

      input {
        border-radius: 50px;
        background-color: #edf9ff;
        border: 1px solid #e5f6ff;
      }
    }
  }

  .manage-faq {
    margin: 25px 0px 0px 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    padding: 30px 45px;
    border-radius: 3px;

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    .icon {
      color: #e73d8e;
    }

    img {
      width: 40px;
      border-radius: 50%;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;

      &:hover {
        color: #4fa0ca;
      }
    }

    .Active,
    .Inactive {
      background-color: green;
      color: #fff;
      padding: 5px 15px;
      font-size: 12px;
    }

    .Inactive {
      background-color: tomato;
    }

    h6 {
      font-weight: 400 !important;
    }
  }
}

.modal {
  label {
    text-align: left;
  }

  .list-group {
    .list-group-item {
      padding: 10px 0;
    }
  }

  .form-group {
    position: relative;
    text-align: left;
  }
}

@media (max-width: 767px) {
  .manage_faq_page {
    h5 {
      font-size: 20px !important;
    }

    .col-md-5.pr-0 {
      padding: 0;
      width: auto;

      input {
        width: 220px;
      }
    }
  }

  .add_faq_page {
    h5 {
      font-size: 20px !important;
    }

    .col-md-12.px-0.form_col {
      padding: 0 !important;
    }
  }
}
