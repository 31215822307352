.mw-170 {
  min-width: 170px;
}

.mw_120 {
  min-width: 120px;
}

.manage-doctor-wrapper {
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }

  .search_col {
    .form-group {
      margin-bottom: 0;
      input {
        border-radius: 50px;
        background-color: #edf9ff;
        border: 1px solid #e5f6ff;
      }
    }
  }

  .right_col {
    button {
      float: right;
      background-color: #edf9ff;
      border-radius: 50px;
      box-shadow: 0 0 30px rgb(0 0 0 / 3%) !important;
      font-size: 13px;
      font-weight: 600;
      color: #717171;
      border: none;
    }
  }

  .manage-doctor {
    margin: 25px 0px 0px 0;
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;

    button {
      background-color: #4fa0ca;
      border-color: #4fa0ca;

      &:hover {
        background-color: #2e58a6;
      }
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      color: #474747;
      padding-bottom: 20px;
    }

    td.approve {
      span {
        cursor: pointer;
      }
    }

    th,
    td {
      font-size: 14px;
      color: #474747;
      vertical-align: middle;

      span.complete{
        color:green;
      }

      span.incomplete{
        color:red;
      }
    }

    h6 {
      font-weight: 400 !important;
    }

    .icon {
      color: #4fa0ca;
    }

    img {
      width: 40px;
      height: 40px;
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
    }

    .active,
    .suspended {
      background-color: rgb(93, 155, 0);
      color: #fff;
      padding: 4px 15px;
      font-size: 13px;
      border-radius: 50px;
    }

    .suspended {
      background-color: tomato;
    }

    a i {
      text-decoration: none;
      color: #474747;
      font-size: 16px;

      &:hover {
        color: #4fa0ca;
      }
    }

    .add-doctor-btn {
      font-size: 14px;
      background-color: #e73d8e;
      color: #fff;
      padding: 6px 25px;
    }

    select {
      height: 35px;
      font-size: 13px;
      padding: 5px 20px;
    }
  }
}

.active,
.Active,
.Inactive,
.suspended,
.verified,
.unverified,
.pending,
.confirmed,
.cancelled,
.no-show,
.completed {
  color: #fff;
  background-color: green;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.pending,
.no-show {
  background-color: rgb(204, 168, 6);
}

.suspended,
.Inactive,
.unverified,
.cancelled {
  background-color: tomato;
}

@media (max-width: 991px) {
  .doctor-details {
    .basic-details img.details-image {
      width: 200px !important;
      height: 200px;
    }
  }
}

@media (max-width: 767px) {
  .manage_walkers_page {
    h5 {
      font-size: 20px;
    }

    .col-md-5.pr-0 {
      margin-left: auto;
      width: auto;

      input {
        width: 220px;
      }
    }
  }

  .doctor-details {
    h3 {
      font-size: 20px;
    }
    .col-md-8.pr-0 {
      padding: 0;
    }
  }
}

@media (max-width: 575px) {
  .manage_walkers_page {
    .col-md-5.pr-0 {
      padding: 0;
      width: 100%;
      margin-top: 15px;

      input {
        width: 100%;
      }
    }
  }
}
