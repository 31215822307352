.mw-170 {
  min-width: 170px;
}

.mw_120 {
  min-width: 120px;
}

label {
  font-size: 12px;
  font-weight: 600;
  color: #474747;
  margin-bottom: 2px;
}
// .form-control {
//   padding-left: 10px;
//   font-size: 14px;
//   background-color: #e5f6ff;
//   border-radius: 50px;
//   border: 1px solid #dedede;
// }
.manage-doctor-wrapper{
  .search_col {
    .form-group {
      margin-bottom: 0;
      input {
        border-radius: 50px;
        background-color: #edf9ff;
        border: 1px solid #e5f6ff;
        // padding-left: 30px;
      
      }
    }
  }

  .manage-doctor{
    .appointments-filter-submit-btn {
    background-color: #4fa0ca;
    color: #fff;
    padding: 5px 20px 6px 20px;
    font-size: 14px;
  
    &:hover {
      background-color: #2e58a6;
    }
  }
  .form-control {
  padding-left: 10px;
  font-size: 14px;
  background-color: #e5f6ff;
  border-radius: 50px;
  border: 1px solid #dedede;
}
}
}

.active,
.Active,
.Inactive,
.suspended,
.verified,
.unverified,
.pending,
.confirmed,
.cancelled,
.no-show,
.completed {
  color: #fff;
  background-color: green;
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.pending,
.no-show {
  background-color: rgb(204, 168, 6);
}

.suspended,
.Inactive,
.unverified,
.cancelled {
  background-color: tomato;
}

@media (max-width: 991px) {
  .doctor-details {
    .basic-details img.details-image {
      width: 200px !important;
      height: 200px;
    }
  }
}

@media (max-width: 767px) {
  .manage_walkers_page {
    h5 {
      font-size: 20px;
    }

    .col-md-5.pr-0 {
      margin-left: auto;
      width: auto;

      input {
        width: 220px;
      }
    }
  }

  .doctor-details {
    h3 {
      font-size: 20px;
    }
    .col-md-8.pr-0 {
      padding: 0;
    }
  }
}

@media (max-width: 575px) {
  .manage_walkers_page {
    .col-md-5.pr-0 {
      padding: 0;
      width: 100%;
      margin-top: 15px;

      input {
        width: 100%;
      }
    }
  }
}
