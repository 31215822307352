// .table-width {
//   width: 25px
// }

.accepted {
  background-color: rgb(235, 255, 18);
  padding: 4px 10px 5px 10px;
  font-size: 12px;
  border-radius: 2px;
}

.select-status {
  background-color: #edf9ff;
  border-radius: 50px;
  border: 1px solid #e5f6ff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #717171;
  height: 25px;
}

.booking-table {
  thead {
    background-color: #4747470d;
    th {
      padding: 5px;
      border-top: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 65px;
      padding: 8px;
      text-align: center;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #00000003;
      }
    }

    td {
      border: none;

      .active {
        border-radius: 50px;
      }

      .btn {
        background-color: #e5f6ff;
        border-radius: 50px;
        border: 1px solid #dedede;
        padding: 3px 20px;

        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
}
.appointments-wrapper {
  .form-group {
    margin-bottom: 0;
  }
  .search_col input {
    border-radius: 50px;
    background-color: #edf9ff;
    border: 1px solid #e5f6ff;
  }

  .right_col button {
    float: right;
    background-color: #edf9ff;
    border-radius: 50px;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%) !important;
    font-size: 13px;
    font-weight: 600;
    color: #717171;
  }
  h5 {
    font-size: 22px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 30px;
  }
  .reviewIndication{
    background-color: 'red';
  }

  .appointments {
    background-color: #edf9ff;
    box-shadow: 0 0 30px rgb(0 0 0 / 3%);
    padding: 30px;
    border-radius: 20px;
    text-align: Center;
    margin: 25px 0px 0px 0;

    th,
    td {
      font-size: 14px;
      color: #474747;
    }

    i {
      font-size: 16px;
      color: #474747;
    }

    .icon {
      color: #4fa0ca;
    }

    .date-picker-label {
      font-size: 12px;
      font-weight: 600;
      color: #474747;
      margin-bottom: 2px;
      display: flex;
      align-items: start;
    }

    .form-control {
      padding-left: 10px;
      font-size: 14px;
      background-color: #e5f6ff;
      border-radius: 50px;
      border: 1px solid #dedede;
    }

    .form-control2 {
      padding-left: 10px;
      font-size: 14px;
      background-color: #e5f6ff;
      border-radius: 50px;
      border: 1px solid #dedede;
    }

    .appointments-filter-submit-btn {
      background-color: #4fa0ca;
      color: #fff;
      padding: 5px 20px 6px 20px;
      font-size: 14px;

      &:hover {
        background-color: #2e58a6;
      }
    }

    .react-datepicker {
      button {
        outline: none !important;
      }
    }

    .reload-btn {
      margin-left: 20px;
      cursor: pointer;

      .fa-refresh {
        color: #e73d8e;
        margin-top: 7px;
      }
    }
  }
}

.doc-image-bg {
  height: 100vh;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #474747;
}

.Toastify__close-button {
  outline: none !important;
}

.datepicker-wrapper {
  position: relative;
  display: inline-block;
}

.datepicker-input {
  padding-left: 30px !important;
  cursor: pointer;
}

.calendar-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}